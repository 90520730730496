import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { calendarIcon } from "../assests/images";
import { Button, ButtonDisabled, ButtonSecondary } from "../styles";
import styles from "../Utils/Global.module.css";
import { DateServiceText, GoogleHeader, TeamClientText } from "./Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { CalendarContext } from "../App";
import useApi from "../Utils/useApi";
import { FB_AZURE_API_URL } from "../services/AuthService";
import { TData, TPartialData } from "./SyncEntriesContainer";
import { useNavigate } from "react-router-dom";
import { RoutesList, UiData } from "../constants/data";
import ModalDialog from "../Utils/ModalDialog";
import useModal from "../Utils/useModal";

interface IRow {
  data?: TPartialData[];
  businessId?: string;
  disabled: boolean;
}

function SyncEntriesHeader({ data, businessId, disabled }: IRow) {
  const dataBusinessCalendar = JSON.parse(
    localStorage.getItem("dataBusinessCalendar")!
  );
  const [content, setContent] = useState<any>("");
  const [upgradeContent, setUpgradeContent] = useState<string>("");
  const [title, setTitle] = useState<string>();
  const [cancel, setCancel] = useState<string>();
  const [success, setSuccess] = useState<string>();
  const [apiFail, setApiFail] = useState<boolean>(false);
  const context = useContext(CalendarContext);
  const { makeRequest, data: response } = useApi({});
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (response?.errorMessage === null && response?.clientLimit === null) {
      navigate(
        `${RoutesList.SYNC_CALENDAR_EVENTS}?synced-successfully=true&entries=${data?.length}`
      );
    } else if (
      response?.errorMessage === null &&
      response?.clientLimit !== null
    ) {
      toggle();
      setContent(
        <TeamClientText>
          Your lite plan allows for {response?.clientLimit.totalSeats} clients.
          Importing <b>{response?.clientLimit.requestedSeats} new clients</b>{" "}
          will require upgrading your plan.
        </TeamClientText>
      );
      setUpgradeContent(
        " Please upgrade your FreshBooks plan before proceeding."
      );
      setTitle(UiData.syncEntries.upgradePlan.header);
      setCancel(UiData.syncEntries.upgradePlan.cancel);
      setSuccess(UiData.syncEntries.upgradePlan.successBtn);
    } else if (
      response?.errorMessage !== null &&
      response?.clientLimit === null
    ) {
      toggle();
      setApiFail(true);
      setContent(UiData.syncEntries.apiFail.footer);
      setTitle(UiData.syncEntries.apiFail.header);
      setCancel(UiData.syncEntries.apiFail.cancel);
      setSuccess(UiData.syncEntries.apiFail.successBtn);
    }
  }, [response]);

  const handleClick = () => {
    makeRequest({
      method: "post",
      url: `${FB_AZURE_API_URL}/business/${businessId}/time_entries`,
      headers: {
        "X-FreshbookAuthToken": localStorage.getItem("access_token"),
      },
      params: {
        businessId: businessId,
        AccountId: dataBusinessCalendar?.business?.accountId,
        BusinessName: dataBusinessCalendar?.business?.businessName,
        CalendarId: dataBusinessCalendar?.calendar?.id,
        CalendarName: dataBusinessCalendar?.calendar?.summary,
        LastSyncStartDate: dataBusinessCalendar?.dates.startDate,
        LastSyncEndDate: dataBusinessCalendar?.dates?.endDate,
      },
      data,
    });
  };

  return (
    <>
      <Stack className={styles.entriesHeader}>
        <Stack className={styles.imageHeader}>
          <img src={calendarIcon} width={39}></img>
          <GoogleHeader>Google Calendar</GoogleHeader>
        </Stack>
        <Stack className={styles.buttonHeader}>
          <ButtonSecondary
            onClick={() => navigate(RoutesList.SELECT_START_END_DATE)}
          >
            Cancel
          </ButtonSecondary>
          {disabled || data?.length === 0 ? (
            <ButtonDisabled>Sync Entries</ButtonDisabled>
          ) : (
            <Button onClick={handleClick}>Sync Entries</Button>
          )}
        </Stack>
      </Stack>
      <Stack className={styles.calendarBusinessDetailsContainer}>
        <Stack className={styles.calendarBusinessDetails}>
          <DateServiceText>Currently Syncing</DateServiceText>
          <TeamClientText>
            {context.dataBusinessCalendar?.calendar?.summary ||
              JSON.parse(localStorage.getItem("dataBusinessCalendar")!).calendar
                .summary}
          </TeamClientText>
        </Stack>
        <Stack className={styles.calendarBusinessDetails}>
          <DateServiceText>Sync to Business</DateServiceText>
          <TeamClientText>
            {" "}
            {context.dataBusinessCalendar?.business?.businessName ||
              JSON.parse(localStorage.getItem("dataBusinessCalendar")!).business
                .businessName}
          </TeamClientText>
        </Stack>
      </Stack>
      <ModalDialog
        isShowing={isShowing}
        title={title}
        hide={toggle}
        upgradeContent={upgradeContent}
        cancel={cancel}
        success={success}
        content={content}
        handleSuccess={
          apiFail
            ? handleClick
            : () => {
                window.open("https://www.freshbooks.com/pricing ", "_blank");
              }
        }
      />
    </>
  );
}

export default SyncEntriesHeader;
