import React, { useState, useContext, useCallback, createContext } from "react";
import Toaster, { ToastProps } from "./Toaster";

const ToastContext = createContext(null);
let id = 1;

const ToastProvider = ({ children }: any) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    ({
      header,
      content,
      success = true,
      link = "",
    }: {
      header: string;
      content: string;
      success: boolean;
      link: string;
    }) => {
      setToasts((toasts) => [
        ...toasts,
        {
          id: id++,
          content,
          header,
          success,
          link,
        },
      ]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id: any) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider
      // @ts-ignore
      value={{
        addToast,
        removeToast,
      }}
    >
      <Toaster toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toastHelpers = useContext(ToastContext);
  return toastHelpers;
};

export { ToastContext, useToast };
export default ToastProvider;
