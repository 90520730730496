import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { UiData } from "../constants/data";
import {
  Button,
  CalendarPermissionContainer,
  CalendarPermissionText,
  CheckCircleIcon,
  MainContainer,
} from "../styles";
import { FirstHeader, Footer, Paragraph } from "./Typography";
import { Stack } from "@mui/material";
import {
  googleLogout,
  useGoogleLogin,
  hasGrantedAllScopesGoogle,
} from "@react-oauth/google";
import axios from "axios";
import { RoutesList } from "../constants/data";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import { colors } from "../constants/styles";
import { useToast } from "../Utils/ToastProvider";
import Link from "@mui/material/Link";
import styles from "../Utils/Global.module.css";

function CalendarPermission() {
  const [user, setUser]: any = useState([]);
  const [profile, setProfile] = useState([]);
  const navigate = useNavigate();
  //@ts-ignore
  const { addToast } = useToast();

  const login = useGoogleLogin({
    scope: process.env.REACT_APP_GOOGLE_SCOPE,
    onSuccess: (codeResponse: any) => {
      setUser(codeResponse);
      localStorage.setItem("g_access_token", codeResponse?.access_token);
      localStorage.removeItem("dataBusinessCalendar");
      const hasAccess = hasGrantedAllScopesGoogle(
        codeResponse,
        "https://www.googleapis.com/auth/calendar.events.readonly",
        "https://www.googleapis.com/auth/calendar.calendarlist.readonly"
      );
      hasAccess
        ? navigate(RoutesList.SYNC_CALENDAR_EVENTS)
        : addToast({
            header: "Permission Denied  ",
            content: "Please Select all the permissions to proceed",
            success: false,
          });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <CalendarPermissionContainer>
      <Header />
      <Stack
        alignItems="flex-start"
        gap={2}
        className={styles.calendarCheckBoxContainer}
      >
        <FirstHeader> {UiData.AccessPermission.header}</FirstHeader>
        <Paragraph>{UiData.AccessPermission.subHeader}</Paragraph>
        <CalendarPermissionText>
          <Typography fontSize={14}>
            {UiData.AccessPermission.firstPermission}
          </Typography>
          <CheckCircleIcon />
        </CalendarPermissionText>
        <CalendarPermissionText>
          <Typography fontSize={14}>
            {UiData.AccessPermission.secondPermission}
          </Typography>
          <CheckCircleIcon />
        </CalendarPermissionText>
        <CalendarPermissionText>
          <Typography fontSize={14}>
            {UiData.AccessPermission.thirdPermission}
          </Typography>
          <CheckCircleIcon />
        </CalendarPermissionText>
        <CalendarPermissionText>
          <Typography fontSize={14}>
            {UiData.AccessPermission.fourthPermission}
          </Typography>
          <CheckCircleIcon />
        </CalendarPermissionText>
        <CalendarPermissionText>
          <Typography fontSize={14}>
            {UiData.AccessPermission.fifthPermission}
          </Typography>
          <CheckCircleIcon />
        </CalendarPermissionText>
        <Button variant="contained" sx={{ mt: 2, textTransform: "none !important" }} onClick={() => login()}>
          {" "}
          {UiData.AccessPermission.buttonText}
        </Button>
        <Divider className={styles.dividerCalendarPermission} />
        <Footer>
          {UiData.AccessPermission.footerText1}
          <Link href={UiData.AccessPermission.faqLink} target="_blank">
            FAQ page
          </Link>
          {UiData.AccessPermission.footerText2}
        </Footer>
      </Stack>
    </CalendarPermissionContainer>
  );
}

export default CalendarPermission;
