import React, { useState, useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import Header from "./Header";
import { FirstHeader, Paragraph, SelectBoxText } from "./Typography";
import { RoutesList, UiData } from "../constants/data";
import { Button, MainContainer } from "../styles";
import { CalendarContext } from "../App";
import { useNavigate } from "react-router-dom";
import Loading from "../Utils/Loading";
import { FB_AZURE_API_URL } from "../services/AuthService";
import useApi from "../Utils/useApi";
import AutocompleteSelect from "../Utils/AutoCompleteSelect";
import styles from "../Utils/Global.module.css";

const dataBusinessCalendar = JSON.parse(
  localStorage.getItem("dataBusinessCalendar")!
);
const businessSelected = dataBusinessCalendar?.business;
const calendarSelected = dataBusinessCalendar?.calendar;

function EditBusinessAndCalendar() {
  const context = useContext(CalendarContext);
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem(
      "dataBusinessCalendar",
      JSON.stringify(context.dataBusinessCalendar)
    );
    navigate(`${RoutesList.SYNC_CALENDAR_EVENTS}?edit=true`);
  };

  const { data: businessData, makeRequest: getFreshbookBusiness } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/businesses`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
    },
  });

  const { data: calendarData } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/calendars`,
    headers: {
      "X-GoogleAuthToken": localStorage.getItem("g_access_token"),
    },
  });

  return (
    <div>
      <MainContainer>
        <Header />
        <Stack alignItems="flex-start" gap={2}>
          <FirstHeader>
            {UiData.selectBusinessAndCalendar.headerEdit}
          </FirstHeader>
          <Stack alignItems="flex-start">
            <SelectBoxText>
              {UiData.selectBusinessAndCalendar.firstQuestion}
            </SelectBoxText>
            {businessData ? (
              <AutocompleteSelect
                onChange={(value: any) => {
                  context!.setData({ business: value });
                }}
                businessCalendarData={businessData}
                businessCalendarSelected={businessSelected}
              />
            ) : (
              <Loading />
            )}
          </Stack>
          <Stack alignItems="flex-start">
            <SelectBoxText>
              {UiData.selectBusinessAndCalendar.secondQuestion}
            </SelectBoxText>
            {calendarData ? (
              <AutocompleteSelect
                onChange={(value: any) => {
                  context!.setData({ calendar: value });
                }}
                businessCalendarData={calendarData}
                businessCalendarSelected={calendarSelected}
              />
            ) : (
              <Loading />
            )}
          </Stack>
          <Paragraph className={styles.selectBusinessAndCalendarFooter}>
            {UiData.selectBusinessAndCalendar.footerText}
          </Paragraph>
          <Button
            className={styles.selectBusinessAndCalendarFooter}
            onClick={handleClick}
          >
            {" "}
            {UiData.selectBusinessAndCalendar.buttonEdit}
          </Button>
        </Stack>
      </MainContainer>
    </div>
  );
}

export default EditBusinessAndCalendar;
