import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { ModalTitle, TeamClientText } from "../components/Typography";
import { Button, ButtonSecondary } from "../styles";
import styles from "../Utils/Global.module.css";
import { colors } from "../constants/styles";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root ": {
    width: "35%",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: colors.modalTitle }}
      {...other}
    >
      {children}
    </DialogTitle>
  );
}

interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  content: string;
  upgradeContent?: string;
  handleSuccess?: any;
  title?: string;
  cancel?: string;
  success?: string;
}

export default function ModalDialog({
  isShowing,
  hide,
  content,
  handleSuccess,
  title,
  cancel,
  success,
  upgradeContent,
}: ModalProps) {
  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    handleSuccess();
    hide();
  };

  return (
    <>
      {isShowing ? (
        <Box>
          <BootstrapDialog
            onClose={hide}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={hide}>
              <ModalTitle>{title ? title : "Confirm"}</ModalTitle>
            </BootstrapDialogTitle>
            <DialogContent>
              {upgradeContent !== "" && upgradeContent != null ? (
                <Stack gap={2}>
                  <TeamClientText gutterBottom>{content}</TeamClientText>
                  <TeamClientText gutterBottom>{upgradeContent}</TeamClientText>
                </Stack>
              ) : (
                <TeamClientText gutterBottom>{content}</TeamClientText>
              )}
            </DialogContent>
            <DialogActions className={styles.modalBtnContainer}>
              <ButtonSecondary onClick={hide}>
                {cancel ? cancel : "Cancel"}
              </ButtonSecondary>
              <Button className={styles.modalOkBtn} onClick={handleClose}>
                {success ? success : "OK"}
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </Box>
      ) : null}
    </>
  );
}
