import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ButtonSecondary, DropdownStyle } from "../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { RoutesList, UiData } from "../constants/data";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import { ReactComponent as CalendarIcon } from "../assests/images/Calendar.svg";
import { ReactComponent as DisconnectIcon } from "../assests/images/Disconnect.svg";

export default function DropDownButton() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnectCalendar = () => {
    googleLogout();
    localStorage.removeItem("g_access_token");
    navigate(RoutesList.CALENDAR_PERMISSION);
    setAnchorEl(null);
  };

  const handleEditBusinessOrCalendar = () => {
    navigate(RoutesList.EDIT_BUSINESS_AND_CALENDAR);
    setAnchorEl(null);
  };

  return (
    <div>
      <DropdownStyle
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {UiData.SyncCalendar.settingButton.text} <KeyboardArrowDownIcon />
      </DropdownStyle>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          ".MuiPaper-root": {
            border: "1px solid #CCD1D9",
            fontFamily: "IBM Plex Sans",
            boxShadow: "4px 4px 0px rgba(6, 41, 66, 0.0710202)",
          },
        }}
      >
        <MenuItem
          onClick={handleDisconnectCalendar}
          sx={{ gap: 1, fontFamily: "IBM Plex Sans" }}
        >
          {" "}
          <DisconnectIcon />
          {UiData.SyncCalendar.settingButton.firstOption}
        </MenuItem>
        <MenuItem
          onClick={handleEditBusinessOrCalendar}
          sx={{ gap: 1, fontFamily: "IBM Plex Sans" }}
        >
          <CalendarIcon />
          {UiData.SyncCalendar.settingButton.secondOption}
        </MenuItem>
      </Menu>
    </div>
  );
}
