import React, { useEffect } from "react";
import {
  CreateTimeEntries,
  EasierInvoicing,
  ImportAppointment,
  syncImage,
} from "../assests/images";
import { Stack } from "@mui/material";
import { RoutesList, UiData } from "../constants/data";
import {
  Button,
  ButtonSecondary,
  MainContainer,
  SyncCalEventStack,
  SyncCalEventsGrid,
} from "../styles";
import Header from "./Header";
import { DateContent, MainText, PaginationFooter } from "./Typography";
import DropDownButton from "../Utils/DropDownButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "../Utils/ToastProvider";
import useApi from "../Utils/useApi";
import { FB_AZURE_API_URL } from "../services/AuthService";
import Loading from "../Utils/Loading";
import styles from "../Utils/Global.module.css";
import { ReactComponent as FBInvoiceIcon } from "../assests/images/FBInvoice.svg";

function SyncCalEvents() {
  const dataBusinessCalendar = JSON.parse(
    localStorage.getItem("dataBusinessCalendar")!
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit") as string;
  const syncedSuccessfully = searchParams.get("synced-successfully") as string;
  const entries = searchParams.get("entries") as string;

  // @ts-ignore
  const { addToast } = useToast();

  const { data: responseLastSync, makeRequest } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/last_sync_dates`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
      "X-GoogleAuthToken": localStorage.getItem("g_access_token"),
    },
    params: {
      businessId: dataBusinessCalendar?.business?.id,
      calendarId: dataBusinessCalendar?.calendar?.id,
    },
  });

  useEffect(() => {
    if (
      responseLastSync !== null &&
      responseLastSync !== "" &&
      responseLastSync !== "fail"
    ) {
      let dataBusinessCalendar = {
        business: {
          id: responseLastSync?.businessId,
          businessUUID: "",
          businessName: responseLastSync?.businessName,
          accountId: responseLastSync?.accountId,
        },
        calendar: {
          id: responseLastSync?.calendarId,
          summary: responseLastSync?.calendarName,
          description: null,
        },
        dates: {
          startDate: responseLastSync?.lastSyncStartDate,
          endDate: responseLastSync?.lastSyncEndDate,
        },
      };

      localStorage.setItem(
        "dataBusinessCalendar",
        JSON.stringify(dataBusinessCalendar)
      );
      localStorage.setItem(
        "lastSyncDates",
        JSON.stringify(dataBusinessCalendar?.dates)
      );
    } else {
      localStorage.removeItem("lastSyncDates");
    }
  }, [responseLastSync]);

  useEffect(() => {
    if (edit === "true") {
      addToast({
        header: UiData.SyncCalendar.toasterEdit.header,
        content: UiData.SyncCalendar.toasterEdit.footer,
      });
      return;
    } else if (syncedSuccessfully === "true") {
      addToast({
        header: UiData.SyncCalendar.toasterSyncSuccess.header,
        content: `Successfully imported ${entries} time entries to ${dataBusinessCalendar?.business?.businessName}.`,
        link: UiData.SyncCalendar.toasterSyncSuccess.link,
      });
      return;
    }
    addToast({
      header: UiData.SyncCalendar.toaster.header,
      content: UiData.SyncCalendar.toaster.footer,
    });
  }, []);

  const openInNewTab = () => {
    window.open(UiData.SyncCalendar.secondaryButonUrl, "_blank", "noreferrer");
  };

  const handleClick = () => {
    if (
      responseLastSync !== null &&
      responseLastSync !== "" &&
      responseLastSync !== "fail"
    ) {
      navigate(RoutesList.SELECT_START_END_DATE);
    } else if (edit === "true") {
      navigate(RoutesList.SELECT_START_END_DATE);
    } else {
      navigate(RoutesList.SELECT_BUSINESS_AND_CALENDAR);
    }
  };

  return (
    <MainContainer>
      <DropDownButton />
      <Header />
      <SyncCalEventStack>
        <MainText> {UiData.SyncCalendar.header}</MainText>
        <SyncCalEventsGrid>
          <img src={ImportAppointment}></img>
          <img src={CreateTimeEntries}></img>
          <img src={EasierInvoicing}></img>
          {UiData.SyncCalendar.gridItems.map((item) => (
            <Stack className={styles.SyncEventsGridContent}>
              <PaginationFooter>{item.header}</PaginationFooter>
              <DateContent>{item.footer}</DateContent>
            </Stack>
          ))}
        </SyncCalEventsGrid>
        {responseLastSync !== null && responseLastSync !== "" ? (
          <Stack className={styles.syncFbInvoiceBtn}>
            <Button
              onClick={handleClick}
              className={styles.SyncCalendarEventsButton}
            >
              {UiData.SyncCalendar.primaryButtonText}
            </Button>
            <Stack className={styles.fbIncvoiceBtnAndIcon}>
              <FBInvoiceIcon />
              <ButtonSecondary onClick={openInNewTab} className={styles.fbInvoiceBtn}>
                {UiData.SyncCalendar.secondaryButtonText}
              </ButtonSecondary>
            </Stack>
          </Stack>
        ) : (
          <Loading />
        )}
      </SyncCalEventStack>
    </MainContainer>
  );
}

export default SyncCalEvents;
