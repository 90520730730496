import React, { useState, useContext, useEffect } from "react";
import { Stack, Divider } from "@mui/material";
import { calendarIcon, DateFilterIcon } from "../assests/images";
import { Button, ButtonFilterDate, ButtonSecondary } from "../styles";
import styles from "../Utils/Global.module.css";
import {
  DateServiceText,
  GoogleHeader,
  MainText,
  PaginationFooter,
  SyncEntriesFirstHeader,
  TeamClientText,
} from "./Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SyncEntriesHeader from "./SyncEntriesHeader";
import EntriesTable from "./EntriesTable";
import useModal from "../Utils/useModal";
import ModalDialog from "../Utils/ModalDialog";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useApi from "../Utils/useApi";
import { FB_AZURE_API_URL } from "../services/AuthService";
import { CalendarContext } from "../App";
import Loading from "../Utils/Loading";
import { calculateTotalHours } from "../Utils/Util";
import { colors } from "../constants/styles";
import { RoutesList } from "../constants/data";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export type TData = {
  id: number;
  teamMember: any;
  client: any;
  date: string;
  service: any;
  duration: string;
  notes: string;
  selected: boolean;
};

const REQUIRED_PROPS = new Set(["teamMember", "client", "duration", "date"]);
export type TPartialData = Partial<TData>;

function SyncEntriesContainer() {
  const dataBusinessCalendar = JSON.parse(
    localStorage.getItem("dataBusinessCalendar")!
  );
  const SIZE = 2;
  const rowsToBeDisplayed = [20, 40, 50, 100];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState("20");
  const [data, setData] = useState<{
    ready: TPartialData[];
    review: TPartialData[];
  }>({ ready: [], review: [] });
  const [activeTab, setActiveTab] = useState<"ready" | "review">("ready");
  const [activeData, setActiveData] = useState<TPartialData[]>();
  const [count, setCount] = useState({ ready: 0, review: 0 });
  const context = useContext(CalendarContext);
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();

  const { data: eventsData, makeRequest } = useApi({
    method: "post",
    enabled: true,
    url: `${FB_AZURE_API_URL}/calendarEvents`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
      "X-GoogleAuthToken": localStorage.getItem("g_access_token"),
    },
    data: {
      calendarId: dataBusinessCalendar?.calendar?.id,
      accountId: dataBusinessCalendar?.business?.accountId,
      startDate: dataBusinessCalendar?.dates?.startDate,
      endDate: dataBusinessCalendar?.dates?.endDate,
      freshbookTimezone: "",
    },
  });

  useEffect(() => {
    const partialData = (eventsData as any[])?.filter((row) => {
      let check = Object.keys(row).filter(
        (key) => REQUIRED_PROPS.has(key) && row[key] !== null
      );
      return check.length === REQUIRED_PROPS.size;
    });

    const partialDataReview = (eventsData as any[])?.filter((row) => {
      let checkReview = Object.keys(row).filter(
        (key) => REQUIRED_PROPS.has(key) && row[key] !== null
      );
      return checkReview.length !== REQUIRED_PROPS.size;
    });
    setData({ ready: partialData, review: partialDataReview });
  }, [eventsData]);

  useEffect(() => {
    setActiveData({ ...data }?.[activeTab]?.slice(0, parseInt(rowsPerPage)));
  }, [data, rowsPerPage, activeTab]);

  useEffect(() => {
    setCount({
      ...count,
      ready: data?.ready
        ?.slice(0, parseInt(rowsPerPage))
        .filter((row: TPartialData) => row.selected).length,
      review: data?.review
        ?.slice(0, parseInt(rowsPerPage))
        .filter((row: TPartialData) => row.selected).length,
    });
  }, [activeData]);

  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage(event.target.value);
  };

  let filteredRows = activeData
    ?.slice(0, parseInt(rowsPerPage))
    ?.filter((row: TPartialData) => {
      return row.selected;
    })
    .map(({ selected: boolean, ...rest }) => {
      let resourceObj = {
        identity_id: rest.teamMember?.identity_id
          ? rest.teamMember?.identity_id + ""
          : "",
        started_at: rest.date ? rest.date : "",
        client_id: rest.client?.id ? rest.client?.id + "" : "",
        client_name: rest.client?.displayName ? rest.client?.displayName : "",
        service_id: rest.service?.service_id
          ? rest.service?.service_id + ""
          : null,
        service_name: rest.service?.service_name
          ? rest.service?.service_name
          : null,
        note: rest.notes ? rest.notes : "",
        duration: rest.duration ? rest.duration : "",
      };
      return resourceObj;
    });

  const onDeleteRow = (id: any) => {
    setData((prevData) => ({
      ...prevData,
      [activeTab]: prevData[activeTab].filter((row: any) => row.id !== id),
    }));
  };

  const handleDateFilterClick = () => {
    toggle();
  };

  const moveToReady = (editEntry: any) => {
    setData((prevData) => ({
      ready: [...prevData.ready, editEntry],
      review: prevData.review.filter((row: any) => row.id !== editEntry.id),
    }));
  };

  return (
    <>
      <Stack className={styles.entriesContainer}>
        <SyncEntriesHeader
          data={filteredRows}
          businessId={dataBusinessCalendar?.business?.id}
          disabled={activeTab === "review"}
        />
        <Stack>
          <Tabs>
            <TabList>
              <Tab
                onClick={() => setActiveTab("ready")}
                style={{
                  color: activeTab !== "ready" ? colors.clickAbleLink : "",
                }}
              >
                Ready to Sync ({count["ready"]})
              </Tab>
              <Tab
                onClick={() => setActiveTab("review")}
                style={{
                  color: activeTab !== "review" ? colors.clickAbleLink : "",
                }}
              >
                Needs Review ({count["review"]})
              </Tab>
            </TabList>
            {activeData ? (
              <Stack>
                <Stack className={styles.readyToSyncHeader}>
                  <SyncEntriesFirstHeader>
                    {activeTab === "ready"
                      ? "Choose Time Entries"
                      : "Incomplete Time Entries"}{" "}
                  </SyncEntriesFirstHeader>
                  <ButtonFilterDate onClick={handleDateFilterClick}>
                    {dayjs(dataBusinessCalendar?.dates.startDate).format(
                      "YYYY-MM-DD"
                    )}
                    {" to "}
                    {dayjs(dataBusinessCalendar?.dates.endDate).format(
                      "YYYY-MM-DD"
                    )}
                    <img
                      className={styles.imageFilter}
                      src={DateFilterIcon}
                    ></img>
                  </ButtonFilterDate>
                </Stack>
                {/* @ts-ignore */}
                <EntriesTable
                  {...{
                    rows: activeData,
                    setRows: setActiveData,
                    review: activeTab === "review",
                    onDeleteRow,
                    moveToReady,
                  }}
                />
                <Stack className={styles.footerHours}>
                  {activeTab === "ready" ? (
                    <Stack className={styles.totalHourFooter}>
                      <TeamClientText className={styles.totalTimeFooter}>
                        Total Time:
                      </TeamClientText>{" "}
                      <TeamClientText>
                        {calculateTotalHours(activeData)}
                      </TeamClientText>{" "}
                    </Stack>
                  ) : (
                    <Stack></Stack>
                  )}
                </Stack>
                <Divider />
                <Stack className={styles.paginationFooter}>
                  <PaginationFooter>
                    {/* @ts-ignore */}
                    {activeData?.length ? "1" : "0"}-{activeData?.length} of{" "}
                    {data?.[activeTab]?.length}
                  </PaginationFooter>
                  <Stack className={styles.rowsDropdownPaginationContainer}>
                    <MainText>Items per page:</MainText>
                    <FormControl
                      className={styles.rowsDropdownPagination}
                      size="small"
                    >
                      <Select
                        sx={{
                          ".MuiSelect-select": { border: "2px solid #7F8C9F" },
                        }}
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={rowsPerPage}
                        onChange={handleChange}
                      >
                        {rowsToBeDisplayed.map((rowToBeDisplayed) => (
                          <MenuItem
                            key={rowToBeDisplayed}
                            value={rowToBeDisplayed}
                          >
                            {rowToBeDisplayed}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Loading />
            )}
          </Tabs>
        </Stack>
      </Stack>
      <ModalDialog
        isShowing={isShowing}
        hide={toggle}
        content={
          "Are you sure you want to edit dates? Any changes to current time entries will be lost"
        }
        handleSuccess={() => {
          navigate(RoutesList.SELECT_START_END_DATE);
        }}
      />
    </>
  );
}

export default SyncEntriesContainer;
