import { Button as MUIButton, Stack } from "@mui/material";
import styled from "@emotion/styled";
import { colors } from "./constants/styles";
import { Autocomplete as MUIAutoComplete } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";

export const Button = styled(MUIButton)`
  border-radius: 5px;
  background-color: ${colors.btn.background};
  color: ${colors.btn.text};
  font-family: "Founders Grotesk";
  font-style: normal;
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
  width: fit-content;
  padding: 8px 16px;
  height: 48px;
  white-space: nowrap;
  text-align: center;
  text-transform: capitalize;
  :hover {
    background-color: ${colors.btn.hoverBackground};
    color: ${colors.btn.text};
  }
`;

export const ButtonDisabled = styled(Button)`
  background-color: ${colors.btn.disabledBackGround};
  color: ${colors.btn.text};
  :hover {
    background-color: ${colors.btn.disabledBackGround};
    color: ${colors.btn.text};
    cursor: default;
  }
`;

export const ButtonSecondary = styled(MUIButton)`
  color: ${colors.btn.withoutBorder};
  font-family: "Founders Grotesk";
  font-style: normal;
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
  width: fit-content;
  padding: 8px 16px;
  height: 48px;
  white-space: nowrap;
  text-align: center;
  text-transform: capitalize;
`;

export const MainContainer = styled(Stack)`
  max-width: 900px;
  margin: auto;
  box-shadow: 4px 4px 0px rgba(6, 41, 66, 0.0710202);
  border-adius: 5px;
  border: 2px solid rgba(6, 40, 65, 0.20287);
  margin-top: 40px;
  padding: 28px;
  padding-bottom: 115px;
  position: relative;
  align-items: center;
`;

export const CalendarPermissionContainer = styled(MainContainer)`
  padding-bottom: 45px;
`;

export const CalendarPermissionText = styled(Stack)`
  align-items: center;
  flex-direction: row;
  gap: 4px;
  margin-left: 24px;
  justify-content: space-between;
  width: 95%;
`;

export const DateStack = styled(Stack)`
  align-items: flex-start;
  width: 180px;
  gap: 8px;
`;

export const SyncCalEventStack = styled(Stack)`
  text-align: left;
  margin: auto;
  max-width: 59%;
  gap: 24px;
`;

export const AutoComplete = styled(MUIAutoComplete)`
  width: 528px;
  mt: 10px;
`;

export const CheckCircleIcon = styled(CheckIcon)`
  color: ${colors.checkBoxPermission};
`;

export const DropdownStyle = styled(ButtonSecondary)`
  font-size: 20px;
  gap: 4px;
  line-height: 24px;
  position: absolute;
  top: 15px;
  right: 15px;
  fontsize: 20px !important;
`;

export const SyncItems = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  gap: 8px;
`;

export const SyncActions = styled(SyncItems)`
  grid-template-columns: 1fr 1fr 1.54fr;
`;

export const SyncCalEventsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 11px;
  row-gap: 24px;
`;

export const ButtonFilterDate = styled(MUIButton)`
  border: 2px solid ${colors.dateFilterButtonBorder};
  border-radius: 5px;
  background-color: ${colors.dateFilterButtonBackground};
  color: ${colors.mainText};
  font-family: "Founders Grotesk";
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  width: fit-content;
  padding: 8px 16px;
  height: 40px;
  white-space: nowrap;
  text-align: center;
  text-transform: none;
`;

export const borderColors = ["red", "blue", "yellow", "green"];

export const ClientInitials = styled.div<{ borderColor: string }>`
  ${({ borderColor }) => `
align-items: center;
  display: flex;
  border: 2px solid ${borderColor};
  border-radius: 50%;
  background: white;
  text-transform: capitalize;
  justify-content: center;
  width: 40px;
  height: 40px;
`}
`;

export const ClientOptionDetails = styled(Stack)``;
