import React, { useState, useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import Header from "./Header";
import { FirstHeader, Paragraph, SelectBoxText } from "./Typography";
import { RoutesList, UiData } from "../constants/data";
import { Button, MainContainer } from "../styles";
import { CalendarContext } from "../App";
import { FB_AZURE_API_URL } from "../services/AuthService";
import Loading from "../Utils/Loading";
import AutocompleteSelect from "../Utils/AutoCompleteSelect";
import useApi from "../Utils/useApi";
import { useNavigate } from "react-router-dom";
import styles from "../Utils/Global.module.css";

function SelectBusinessAndCalendar() {
  const context = useContext(CalendarContext);
  const navigate = useNavigate();

  const { data: businessData, makeRequest } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/businesses`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
    },
  });

  const { data: calendarData } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/calendars`,
    headers: {
      "X-GoogleAuthToken": localStorage.getItem("g_access_token"),
    },
  });

  const checkButtonStatus = () => {
    return !(
      context.dataBusinessCalendar?.business &&
      context.dataBusinessCalendar?.calendar
    );
  };

  const handleClick = () => {
    localStorage.setItem(
      "dataBusinessCalendar",
      JSON.stringify(context.dataBusinessCalendar)
    );
    navigate(RoutesList.SELECT_START_END_DATE);
  };

  return (
    <div>
      <MainContainer>
        <Header />
        <Stack alignItems="flex-start" gap={2}>
          <FirstHeader>{UiData.selectBusinessAndCalendar.header}</FirstHeader>
          <Stack alignItems="flex-start">
            <SelectBoxText>
              {UiData.selectBusinessAndCalendar.firstQuestion}
            </SelectBoxText>
            {businessData ? (
              <AutocompleteSelect
                onChange={(value: any) => {
                  context!.setData({ business: value });
                }}
                businessCalendarData={businessData}
              />
            ) : (
              <Loading />
            )}
          </Stack>
          <Stack alignItems="flex-start">
            <SelectBoxText>
              {UiData.selectBusinessAndCalendar.secondQuestion}
            </SelectBoxText>
            {calendarData ? (
              <AutocompleteSelect
                onChange={(value: any) => {
                  context!.setData({ calendar: value });
                }}
                businessCalendarData={calendarData}
              />
            ) : (
              <Loading />
            )}
          </Stack>
          <Paragraph className={styles.selectBusinessAndCalendarFooter}>
            {UiData.selectBusinessAndCalendar.footerText}
          </Paragraph>
          <Button disabled={checkButtonStatus()} onClick={handleClick}>
            {" "}
            {UiData.selectBusinessAndCalendar.buttonText}
          </Button>
        </Stack>
      </MainContainer>
    </div>
  );
}

export default SelectBusinessAndCalendar;
