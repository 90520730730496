import "./App.css";
import CalendarPermission from "./components/CalendarPermission";
import SyncCalEvents from "./components/SyncCalEvents";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutesList } from "./constants/data";
import { getAuthToken } from "./services/AuthService";
import Login from "./components/Login";
import SelectBusinessAndCalendar from "./components/SelectBusinessAndCalendar";
import SelectDates from "./components/SelectDates";
import { createContext, useState } from "react";
import EditBusinessAndCalendar from "./components/EditBusinessAndCalendar";
import ToastProvider from "./Utils/ToastProvider";
import DenyAccess from "./components/DenyAccess";
import SyncEntriesContainer from "./components/SyncEntriesContainer";
import PrivacyPolicy from "./components/PrivacyPolicy";

export const CalendarContext = createContext<any | null>(null);

function App() {
  const [dataBusinessCalendar, setDataState] = useState();
  const setData = (input: any) => {
    setDataState((prev: any) => ({ ...prev, ...input }));
  };
  return (
    <div className="App">
      <ToastProvider>
        <CalendarContext.Provider value={{ dataBusinessCalendar, setData }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path={RoutesList.PRIVACY_POLICY}
                element={<PrivacyPolicy />}
              />
              <Route
                path={RoutesList.CALENDAR_PERMISSION}
                element={<CalendarPermission />}
              />
              <Route
                path={RoutesList.SYNC_CALENDAR_EVENTS}
                element={<SyncCalEvents />}
              />
              <Route
                path={RoutesList.SELECT_BUSINESS_AND_CALENDAR}
                element={<SelectBusinessAndCalendar />}
              />
              <Route
                path={RoutesList.EDIT_BUSINESS_AND_CALENDAR}
                element={<EditBusinessAndCalendar />}
              />
              <Route
                path={RoutesList.SELECT_START_END_DATE}
                element={<SelectDates />}
              />
              <Route path={RoutesList.DENY_ACCESS} element={<DenyAccess />} />
              <Route
                path={RoutesList.SYNC_ENTRIES_CONTAINER}
                element={<SyncEntriesContainer />}
              />
            </Routes>
          </BrowserRouter>
        </CalendarContext.Provider>
      </ToastProvider>
    </div>
  );
}

export default App;
