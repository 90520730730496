import React from "react";
import styles from "../Utils/Global.module.css";
import { Link } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>Privacy Policy</h1>
      <p>Effective date: 05-18-2023</p>
      <p>
        Thank you for using our app <strong>Freshbook Google Calendar</strong>.
        This Privacy Policy outlines how we collect, use, disclose, and protect
        your personal information when you use our App. By using our App, you
        agree to the terms and conditions of this policy.
      </p>
      <h2>Information We Collect</h2>
      <p>
        When you use our App and grant us access to your Google Calendar through
        the Google OAuth verification process, we may collect and store certain
        information, including:
      </p>
      <ol>
        <li>
          <strong>Calendar Events</strong>: We may access your calendar events,
          including event titles, descriptions, start and end times, locations,
          and any other associated information.
        </li>
        <li>
          <strong>Account Information</strong>: We may collect your Google
          account information, such as your email address and name.
        </li>
      </ol>
      <p>
        Please note that we only collect the information necessary to provide
        you with the intended functionality of the App, which is to fetch events
        from your Google Calendar.
      </p>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect from your Google Calendar to:</p>
      <ol>
        <li>
          <strong>Fetch Events</strong>: We utilize the access to your Google
          Calendar to fetch your calendar events and display them within our
          App.
        </li>
        <li>
          <strong>Provide App Functionality</strong>: We use the collected
          information to provide you with the intended features and
          functionality of the App, such as sycnc events in Freshbooks account.
        </li>
      </ol>
      <h2>Information Sharing and Disclosure</h2>
      <p>
        We understand the importance of your privacy and will not share or
        disclose your personal information to third parties except in the
        following circumstances:
      </p>
      <ol>
        <li>
          <strong>With Your Consent</strong>: We may share your personal
          information with third parties if we have obtained your consent to do
          so.
        </li>
        <li>
          <strong>Service Providers</strong>: We may engage third-party service
          providers who assist us in operating our App, such as hosting and
          customer support. But these service providers will not have access to
          any customer information.
        </li>
        <li>
          <strong>Legal Requirements</strong>: We may disclose your personal
          information if required to do so by law or in response to valid legal
          requests, such as a court order or government investigation.
        </li>
      </ol>
      <h2>Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, please note that no
        method of transmission over the internet or electronic storage is 100%
        secure, and we cannot guarantee absolute security.
      </p>
      <h2>Data Retention</h2>
      <p>
        We will retain your personal information for as long as necessary to
        fulfill the purposes outlined in this Privacy Policy unless a longer
        retention period is required or permitted by law.
      </p>
      <h2>Your Choices and Rights</h2>
      <p>
        You have the following choices and rights regarding your personal
        information:
      </p>
      <ol>
        <li>
          <strong>Access and Update</strong>: You may access and update your
          personal information within the App's settings or by contacting us
          directly.
        </li>
        <li>
          <strong>Deletion</strong>: You may request the deletion of your
          personal information by contacting us.
        </li>
      </ol>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We reserve the right to modify this Privacy Policy at any time. Any
        changes will be effective immediately upon posting the updated policy on
        the App. We encourage you to review this Privacy Policy periodically.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns regarding this Privacy Policy or
        our privacy practices, please{" "}
        <Link
          href="https://www.freshbooks.com/contact"
          underline="none"
          target="_blank"
        >
          contact us
        </Link>{" "}
        .
      </p>
      <p>
        By using our App, you acknowledge that you have read and understood this
        Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
