import React, { useEffect } from "react";
import { Button } from "../styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AUTH_URL } from "../services/Constants";
import {
  freshBookLogin,
  getAuthToken,
  getLoggedInUserDetails,
} from "../services/AuthService";
import { RoutesList } from "../constants/data";

function Login() {
  const [searchParams, setSearchParams]: any = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("code")) {
      freshBookLogin(searchParams.get("code"));
      navigate(RoutesList.CALENDAR_PERMISSION);
      return;
    } else if (searchParams.get("error")) {
      navigate(RoutesList.DENY_ACCESS);
    } else {
      window.location.href = AUTH_URL;
    }
  }, []);

  return <div></div>;
}

export default Login;
