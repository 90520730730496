import React, { useEffect } from "react";
import { Button, MainContainer } from "../styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./Header";

function DenyAccess() {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Header />
      <Button onClick={() => navigate("/")}> Login With Freshbook</Button>
    </MainContainer>
  );
}

export default DenyAccess;
