import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Stack,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState, SetStateAction } from "react";
import {
  DateContent,
  DateServiceText,
  PaginationFooter,
  TeamClientText,
} from "./Typography";
import styles from "../Utils/Global.module.css";
import { TData, TPartialData } from "./SyncEntriesContainer";
import {
  Button,
  ButtonSecondary,
  SyncItems,
  SyncActions,
  ClientInitials,
  borderColors,
  ClientOptionDetails,
} from "../styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ArchiveIcon,
  CheckBoxDefaultIcon,
  CheckBoxDisabledIcon,
  CheckBoxSelectedIcon,
  ClientIcon,
  EditIcon,
  SaveCheckIcon,
  ServiceIcon,
  TeamIcon,
  TimeIcon,
} from "../assests/images";
import useModal from "../Utils/useModal";
import ModalDialog from "../Utils/ModalDialog";
import { FB_AZURE_API_URL, getAllClients } from "../services/AuthService";
import useApi from "../Utils/useApi";
import { DateIcon } from "./SelectDates";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { formatHoursAndMinutes } from "../Utils/Util";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "2px solid #CCD1D9 ",
          boxShadow: "2px 2px 0px rgba(6, 41, 66, 0.1);",
        },
      },
    },
  },
});

interface IRow {
  rows: TPartialData[];
  setRows: React.Dispatch<SetStateAction<TPartialData[]>>;
  review?: boolean;
  onDeleteRow: (id: any) => void;
  moveToReady: (editEntry: any) => void;
}

function EntriesTable({
  rows,
  setRows,
  review,
  onDeleteRow,
  moveToReady,
}: IRow) {
  const [isEdit, setIsEdit] = useState<boolean[]>(
    Array(rows?.length).fill(false)
  );
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [style, setStyle] = useState({ display: "none" });
  const [isHovering, setIsHovering] = useState(true);
  const { isShowing, toggle } = useModal();
  const [content, setContent] = useState<string>("");
  const [editStateData, setEditStateData] = useState<any>();
  const [editKey, setEditKey] = useState<any>();
  const [deleteId, setDeleteId] = useState<any>();
  const [durationError, setDurationError] = useState<boolean>();
  let checkEdit = isEdit.includes(true);
  const dataBusinessCalendar = JSON.parse(
    localStorage.getItem("dataBusinessCalendar")!
  );
  const access_token = localStorage.getItem("access_token");
  const filterClient = createFilterOptions<any>();
  const filterService = createFilterOptions<any>();

  useEffect(() => {
    setIsEdit(Array(rows?.length).fill(false));
  }, [review, rows]);

  const { data: allClients } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/clients/${dataBusinessCalendar?.business?.accountId}`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
    },
  });

  const { data: serviceData } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/business/${dataBusinessCalendar?.business?.id}/services`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
    },
  });

  const { data: teamData } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/business/${dataBusinessCalendar?.business?.id}/team_members`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
    },
  });

  const handleRowSelection = (id: number) => {
    setRows(
      rows.map((row) => {
        if (row.id === id) {
          return { ...row, selected: !row.selected };
        }
        return row;
      })
    );
  };

  const handleSelectAll = () => {
    setRows(
      rows.map((row) => ({
        ...row,
        selected: true,
      }))
    );
  };

  const handleDeselectAll = () => {
    setRows(
      rows.map((row) => ({
        ...row,
        selected: false,
      }))
    );
  };

  const deleteItem = () => {
    onDeleteRow(deleteId);
  };

  const handleDelete = (id: any) => {
    setDeleteId(id);
    setContent("Are you sure you want to delete this time entry?");
    toggle();
  };

  const handleMouseOver = (key: any) => {
    setIsHovering(true);
    setEditKey(key);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleChangeEditInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "duration") {
      setEditStateData({
        ...editStateData,
        duration: value,
      });
      /^\d{1,2}:\d{2}$/.test(value)
        ? setDurationError(false)
        : setDurationError(true);
    } else if (name === "notes") {
      setEditStateData({
        ...editStateData,
        notes: value,
      });
    }
  };

  const allSelected = rows?.every((row) => row?.selected);
  const editRowEntry = (index: number, row: TPartialData) => {
    if (!checkEdit) {
      setIsEdit((prev) =>
        prev?.map((val, idx) => (idx === index ? !val : val))
      );
      setEditStateData(row);
      /^\d{1,2}:\d{2}$/.test(row?.duration!)
        ? setDurationError(false)
        : setDurationError(true);
    }
  };

  const handleClientRenderOptions = (props: any, option: any) => (
    <Box
      component="li"
      sx={{
        "& > img": { mr: 2, flexShrink: 0 },
        borderBottom: "1px solid #7F8C9F",
      }}
      {...props}
      key={option?.key}
    >
      <Stack className={styles.clientCardOptions}>
        <ClientInitials
          borderColor={borderColors[Math.floor(Math.random() * 4)]}
        >
          <span>{option?.firstName?.charAt(0)}</span>
          <span>{option?.lastName?.charAt(0)}</span>
        </ClientInitials>
        <ClientOptionDetails>
          <TeamClientText>{option?.displayName}</TeamClientText>
          <DateContent>
            {option?.firstName} {option?.lastName}
          </DateContent>
        </ClientOptionDetails>
      </Stack>
    </Box>
  );
  const handleClientAutoComplete = (
    newValue: any,
    setEditStateData: React.Dispatch<any>,
    editStateData: any
  ) => {
    if (typeof newValue === "string") {
      setEditStateData({
        ...editStateData,
        client: {
          displayName: newValue,
          id: "",
          organizationName: newValue,
          email: newValue,
          firstName: newValue,
          lastName: newValue,
        },
      });
    } else if (newValue && newValue?.inputValue) {
      setEditStateData({
        ...editStateData,
        client: {
          displayName: newValue?.inputValue,
          id: "",
          organizationName: newValue?.inputValue,
          email: newValue?.inputValue,
          firstName: newValue?.inputValue,
          lastName: newValue?.inputValue,
        },
      });
    } else {
      setEditStateData({
        ...editStateData,
        client: newValue,
      });
    }
  };

  const handleServiceAutoComplete = (
    newValue: any,
    setEditStateData: React.Dispatch<any>,
    editStateData: any
  ) => {
    if (typeof newValue === "string") {
      setEditStateData({
        ...editStateData,
        service: {
          service_name: newValue,
          service_id: "",
        },
      });
    } else if (newValue && newValue?.inputValue) {
      setEditStateData({
        ...editStateData,
        service: {
          service_name: newValue?.inputValue,
          service_id: "",
        },
      });
    } else {
      setEditStateData({
        ...editStateData,
        service: newValue,
      });
    }
  };

  const handleClientFilterOptions = (
    options: any,
    params: any,
    filtered: any
  ) => {
    const { inputValue } = params;
    const isExisting = options?.some(
      (option: any) => inputValue === option?.displayName
    );
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        displayName: `Add "${inputValue}"`,
      });
    }
    return filtered;
  };

  const handleServiceFilterOptions = (
    options: any,
    params: any,
    filtered: any
  ) => {
    const { inputValue } = params;
    const isExisting = options?.some(
      (option: any) => inputValue === option?.service_name
    );
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        service_name: `Add "${inputValue}"`,
      });
    }
    return filtered;
  };
  const handleGetLabelOptions = (option: any, client: boolean) => {
    if (typeof option === "string") {
      return option;
    }
    if (option?.inputValue) {
      return option?.inputValue;
    }
    return client ? option?.displayName : option?.service_name;
  };

  const handleSubmitEditData = () => {
    if (
      editStateData?.client &&
      editStateData?.teamMember &&
      !durationError &&
      editStateData?.date !== "undefined"
    ) {
      if (review) {
        moveToReady(editStateData);
        return;
      }
      setRows((rows) =>
        rows.map((row) =>
          row.id === editStateData?.id ? { ...row, ...editStateData } : row
        )
      );
      setIsEdit(isEdit?.map((item) => false));
    }
  };

  return (
    <>
      <table
        style={{ borderCollapse: "collapse", width: "100%" }}
        className={styles.hoverTable}
      >
        <thead>
          <tr className={styles.tableRowHeader}>
            {!review ? (
              <td className={styles.tableHeaderFirstData}>
                <Checkbox
                  disabled={checkEdit}
                  icon={<img src={CheckBoxDefaultIcon} />}
                  checked={allSelected}
                  checkedIcon={
                    <img
                      src={
                        checkEdit ? CheckBoxDisabledIcon : CheckBoxSelectedIcon
                      }
                    />
                  }
                  onChange={(e) =>
                    e.target.checked ? handleSelectAll() : handleDeselectAll()
                  }
                />
              </td>
            ) : null}
            {!review ? <td></td> : null}
            <td className={styles.tableHeaderSecondData}>
              <DateServiceText> Team Member / Date</DateServiceText>
            </td>
            <td className={styles.tableHeaderClient}>
              <DateServiceText> Client</DateServiceText>
            </td>
            <td className={styles.tableHeaderTime}>
              <DateServiceText> Time</DateServiceText>
            </td>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr
              key={row.id}
              className={styles.tableRowHeader}
              onMouseOver={() => handleMouseOver(row?.id)}
              onMouseOut={handleMouseOut}
            >
              {isEdit[index] ? (
                <>
                  <td colSpan={5}>
                    <Stack className={styles.editItemContainer}>
                      <Stack gap={1}>
                        <SyncItems>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              id="client"
                              size="small"
                              value={editStateData?.client}
                              onChange={(event, newValue) => {
                                handleClientAutoComplete(
                                  newValue,
                                  setEditStateData,
                                  editStateData
                                );
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filterClient(options, params);
                                return handleClientFilterOptions(
                                  options,
                                  params,
                                  filtered
                                );
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              options={allClients}
                              getOptionLabel={(option) => {
                                return handleGetLabelOptions(option, true);
                              }}
                              renderOption={(props, option) =>
                                handleClientRenderOptions(props, option)
                              }
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!editStateData?.client}
                                  id="outlined-error"
                                  placeholder="Add a client"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        <img src={ClientIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            <Autocomplete
                              size="small"
                              freeSolo
                              id="service"
                              value={editStateData?.service}
                              onChange={(event, newValue) => {
                                handleServiceAutoComplete(
                                  newValue,
                                  setEditStateData,
                                  editStateData
                                );
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filterService(options, params);
                                return handleServiceFilterOptions(
                                  options,
                                  params,
                                  filtered
                                );
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              options={serviceData}
                              getOptionLabel={(option) => {
                                return handleGetLabelOptions(option, false);
                              }}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": {
                                      mr: 2,
                                      flexShrink: 0,
                                    },
                                    borderBottom: "1px solid #7F8C9F",
                                  }}
                                  {...props}
                                  key={option?.key}
                                >
                                  <Stack>
                                    <TeamClientText>
                                      {option?.service_name}
                                    </TeamClientText>
                                  </Stack>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Add a service"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={ServiceIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </ThemeProvider>
                          <TextField
                            sx={{
                              ".MuiInputBase-root": {
                                paddingLeft: "6px",
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={TimeIcon} />
                                </InputAdornment>
                              ),
                            }}
                            error={durationError}
                            id="outlined-error"
                            size="small"
                            type="text"
                            placeholder="HH:MM"
                            variant="outlined"
                            name="duration"
                            value={editStateData?.duration}
                            onChange={handleChangeEditInput}
                          />
                        </SyncItems>
                        <SyncActions>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              size="small"
                              forcePopupIcon={false}
                              id="team"
                              disablePortal
                              options={teamData}
                              getOptionLabel={(option: any) =>
                                option?.member_name
                              }
                              value={editStateData?.teamMember}
                              isOptionEqualToValue={(option: any, value: any) =>
                                option?.value === value?.value
                              }
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": {
                                      mr: 2,
                                      flexShrink: 0,
                                    },
                                    borderBottom: "2px solid #7F8C9F",
                                    background: "white !important",
                                    "&:hover": {
                                      background: "#E6E9EC !important",
                                    },
                                  }}
                                  {...props}
                                  key={option?.key}
                                >
                                  <Stack>
                                    <TeamClientText>
                                      {option?.member_name}
                                    </TeamClientText>
                                  </Stack>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Add a team member"
                                  error={!editStateData?.teamMember}
                                  id="outlined-error"
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img src={TeamIcon} />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                              onChange={(event, value) => {
                                setEditStateData({
                                  ...editStateData,
                                  teamMember: value,
                                });
                              }}
                            />{" "}
                          </ThemeProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                ".MuiInputBase-root": {
                                  flexDirection: "row-reverse",
                                },
                              }}
                              slots={{
                                openPickerButton: DateIcon,
                              }}
                              slotProps={{
                                textField: {
                                  size: "small",
                                },
                              }}
                              value={dayjs(editStateData?.date)}
                              onChange={(newValue) =>
                                setEditStateData({
                                  ...editStateData,
                                  date: newValue?.format("MM/DD/YYYY") + "",
                                })
                              }
                            />
                          </LocalizationProvider>
                          <TextField
                            sx={{
                              ".MuiInputBase-root": {
                                paddingLeft: "6px",
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={EditIcon} />
                                </InputAdornment>
                              ),
                            }}
                            size="small"
                            id="outlined-basic"
                            placeholder="Add notes"
                            variant="outlined"
                            name="notes"
                            value={editStateData?.notes}
                            onChange={handleChangeEditInput}
                          />
                        </SyncActions>
                      </Stack>
                      <Stack className={styles.actionContainer}>
                        <Button
                          className={styles.checkButton}
                          onClick={handleSubmitEditData}
                        >
                          <img src={SaveCheckIcon} />
                        </Button>
                        <ButtonSecondary
                          className={styles.closeButton}
                          onClick={() =>
                            setIsEdit(isEdit?.map((item) => false))
                          }
                        >
                          <CloseIcon />
                        </ButtonSecondary>
                      </Stack>
                    </Stack>
                  </td>
                </>
              ) : (
                <>
                  {!review ? (
                    <td className={styles.tableBodyFirstData}>
                      <Checkbox
                        disabled={checkEdit}
                        icon={<img src={CheckBoxDefaultIcon} />}
                        checked={row.selected}
                        checkedIcon={
                          <img
                            src={
                              checkEdit
                                ? CheckBoxDisabledIcon
                                : CheckBoxSelectedIcon
                            }
                          />
                        }
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleRowSelection(row?.id!);
                        }}
                      />
                    </td>
                  ) : null}
                  {!review ? (
                    <td>
                      <Divider
                        className={styles.verticalDivider}
                        orientation="vertical"
                        flexItem
                      />
                    </td>
                  ) : null}
                  <td
                    className={styles.tableBodySecondData}
                    onClick={(e) => {
                      e.stopPropagation();
                      editRowEntry(index, row);
                    }}
                  >
                    <TeamClientText>
                      {row?.teamMember?.member_name}{" "}
                    </TeamClientText>
                    <DateServiceText>
                      {dayjs(row?.date).format("MM/DD/YY")}
                    </DateServiceText>
                  </td>
                  <td
                    className={styles.tableBodyThirdData}
                    onClick={(e) => {
                      e.stopPropagation();
                      editRowEntry(index, row);
                    }}
                  >
                    <TeamClientText>
                      {row?.client?.displayName || (
                        <span className={styles.noClientText}>
                          {row?.client?.displayName || "No Client"}
                        </span>
                      )}
                    </TeamClientText>
                    <DateServiceText>
                      <span style={{ fontStyle: "italic" }}>
                        {row?.service?.service_name || "No service"}{" "}
                      </span>
                      {row?.notes ? `• ${row?.notes}` : ""}
                    </DateServiceText>
                  </td>
                  <td
                    className={styles.tableHeaderTime}
                    onClick={(e) => {
                      e.stopPropagation();
                      editRowEntry(index, row);
                    }}
                  >
                    {isHovering && !checkEdit && editKey === row.id && (
                      <Stack
                        flexDirection={"row"}
                        divider={<Divider orientation="vertical" flexItem />}
                        className={styles.editDeleteBtnContainer}
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            editRowEntry(index, row);
                          }}
                        >
                          <img src={EditIcon} width={19} />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(row.id);
                          }}
                        >
                          <img src={ArchiveIcon} width={19} />
                        </IconButton>
                      </Stack>
                    )}
                    <TeamClientText className={styles.durationHour}>
                      {formatHoursAndMinutes(row?.duration)}
                    </TeamClientText>
                    {review ? (
                      <PaginationFooter className={styles.incompleteText}>
                        Incomplete
                      </PaginationFooter>
                    ) : null}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <ModalDialog
        isShowing={isShowing}
        hide={toggle}
        content={content}
        handleSuccess={deleteItem}
      />
    </>
  );
}

export default EntriesTable;
