import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AutoComplete } from "../styles";

export default function AutocompleteSelect({ ...props }: any) {
  const [busCalData, setBusCalData] = useState(props?.businessCalendarSelected);
  const businessCalendarData = props?.businessCalendarData;
  useEffect(() => {
    props?.onChange(props?.businessCalendarSelected);
  }, []);

  return (
    <AutoComplete
      size="small"
      disablePortal
      options={businessCalendarData}
      getOptionLabel={(option: any) => option.businessName || option.summary}
      isOptionEqualToValue={(option: any, value: any) =>
        option.value === value.value
      }
      value={busCalData || null}
      id="combo-box-demo"
      renderInput={(params) => (
        <TextField {...params} placeholder="Please Select" />
      )}
      onChange={(event, value) => {
        if (value) {
          setBusCalData(value);
          props?.onChange(value);
        }
      }}
    />
  );
}
