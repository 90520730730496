import React from "react";
import axios from "axios";
import useApi from "../Utils/useApi";

export const API_URL = process.env.REACT_APP_FRESHBOOK_API_URL;
export const FB_AZURE_API_URL = process.env.REACT_APP_FB_AZURE_API_URL;
const access_token = localStorage.getItem("access_token");

const freshBookLogin = async (code: string) => {
  let tokenFormData = new FormData();
  tokenFormData.append("grant_type", "authorization_code");
  tokenFormData.append("client_id", process.env.REACT_APP_FRESHBOOK_CLIENT_ID!);
  tokenFormData.append(
    "client_secret",
    process.env.REACT_APP_FRESHBOOK_CLIENT_SECRET!
  );
  tokenFormData.append(
    "redirect_uri",
    process.env.REACT_APP_FRESHBOOK_REDIRECT_URI!
  );
  tokenFormData.append("code", code);
  let response = await getAuthToken(tokenFormData);
  if (response?.status === 200) {
    localStorage.setItem("access_token", response?.data.access_token);
  }
};
const getAuthToken = async (payload: any) => {
  try {
    const baseUrl = `${API_URL}/oauth/token`;
    return await axios.post(baseUrl, payload);
  } catch {}
};
const getLoggedInUserDetails = async (accesstoken: any) => {
  try {
    const baseUrl = `${API_URL}/api/v1/users/me`;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accesstoken}`,
      },
    };
    return await axios.get(baseUrl, config);
  } catch (e) {
    console.log("error");
  }
};

const getAllClients = async (accountId: any, token: any) => {
  try {
    const baseUrl = `${FB_AZURE_API_URL}/clients/${accountId}`;
    const config = {
      headers: {
        "content-type": "application/json",
        "X-FreshbookAuthToken": `${token}`,
      },
    };
    return await axios.get(baseUrl, config);
  } catch (e) {
    console.log("error");
  }
};

export { getAuthToken, getLoggedInUserDetails, freshBookLogin, getAllClients };
