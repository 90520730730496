import { getAuthToken, getLoggedInUserDetails } from "../services/AuthService";

export const freshBookLoginUtil = (code: string) => {
  let responseStatus = false;
  let tokenFormData = new FormData();
  tokenFormData.append("grant_type", "authorization_code");
  tokenFormData.append("client_id", process.env.REACT_APP_FRESHBOOK_CLIENT_ID!);
  tokenFormData.append(
    "client_secret",
    process.env.REACT_APP_FRESHBOOK_CLIENT_SECRET!
  );
  tokenFormData.append(
    "redirect_uri",
    process.env.REACT_APP_FRESHBOOK_REDIRECT_URI!
  );
  tokenFormData.append("code", code);
  getAuthToken(tokenFormData)
    .then((response) => {
      localStorage.setItem("access_token", response?.data.access_token);
      getLoggedInUserDetails(response?.data.access_token).then((response) => {
        return response;
      });
      responseStatus = true;
    })
    .catch((error) => console.log("error"));
  return responseStatus;
};

function hoursStringToDecimal(hoursString: string) {
  const [hoursPart, minutesPart] = hoursString.split(":");
  return Number(hoursPart) + Number(minutesPart) / 60;
}

function decimalHoursToString(hoursDecimal: number) {
  const numHours = Math.floor(hoursDecimal);
  const numMinutes = Math.round((hoursDecimal - numHours) * 60);
  if (
    (numHours !== 0 && numMinutes !== 0) ||
    (numHours === 0 && numMinutes === 0)
  )
    return `${numHours + "h "}${numMinutes + "m"}`;
  else if (numHours !== 0 && numMinutes === 0) {
    return `${numHours + "h "}${numMinutes + "m"}`;
  } else {
    return `${numMinutes + "m "}`;
  }
}

export const calculateTotalHours = (data: any) => {
  let sumHours = 0;
  for (let i = 0; i < data.length; i++) {
    sumHours += hoursStringToDecimal(data[i].duration);
  }
  return decimalHoursToString(sumHours);
};

export const formatHoursAndMinutes = (time: any) => {
  let sum = hoursStringToDecimal(time);
  return decimalHoursToString(sum);
};
