import { useEffect, useState } from "react";
import axios, { Method, AxiosRequestConfig } from "axios";
import { useToast } from "./ToastProvider";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config: any) => ({
  ...config,
}));

const useApi = (options: AxiosRequestConfig & { enabled?: boolean }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  // @ts-ignore
  const { addToast } = useToast();
  const makeRequest = async (config?: AxiosRequestConfig) => {
    setLoading(true);
    try {
      let response = await axiosInstance({ ...options, ...config });
      if (response?.status === 200) {
        setData(response?.data);
      } else if (response?.status === 204) {
        setData("fail");
      } else {
        addToast({
          header: "Some Error occurred",
          content: "Please Try Again",
          success: false,
        });
      }
      setLoading(false);
    } catch (e: any) {
      if (config?.url?.includes("time_entries")) {
        setData(e?.response?.data);
        return;
      }
      addToast({
        header: "Some Error occurred",
        content: "Please Try Again",
        success: false,
      });
    }
  };

  useEffect(() => {
    if (options?.enabled || options.method === "get") makeRequest();
  }, []);

  return { loading, data, makeRequest };
};

export default useApi;
