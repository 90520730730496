import React, { useContext, useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { Stack } from "@mui/material";
import Header from "./Header";
import { DateContent, FirstHeader, MainText, Paragraph } from "./Typography";
import { Button, DateStack, MainContainer } from "../styles";
import { RoutesList, UiData } from "../constants/data";
import styles from "../Utils/Global.module.css";
import { FB_AZURE_API_URL } from "../services/AuthService";
import useApi from "../Utils/useApi";
import { CalendarContext } from "../App";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../assests/images/Calendar.svg";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const DateIcon = (props: any) => {
  return (
    <SvgIcon {...props} className={styles.calendarIcon}>
      <CalendarIcon />
    </SvgIcon>
  );
};

function SelectDates() {
  const dataBusinessCalendar = JSON.parse(
    localStorage.getItem("dataBusinessCalendar")!
  );
  const [lastSyncDates, setLastSyncDates] = useState<any>();
  const context = useContext(CalendarContext);
  const navigate = useNavigate();
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(endDate).subtract(1, "week")
  );

  const { data: responseLastSync, makeRequest } = useApi({
    method: "get",
    url: `${FB_AZURE_API_URL}/last_sync_dates`,
    headers: {
      "X-FreshbookAuthToken": localStorage.getItem("access_token"),
      "X-GoogleAuthToken": localStorage.getItem("g_access_token"),
    },
    params: {
      businessId: dataBusinessCalendar?.business?.id,
      calendarId: dataBusinessCalendar?.calendar?.id,
    },
  });

  useEffect(() => {
    if (
      responseLastSync !== null &&
      responseLastSync !== "" &&
      responseLastSync !== "fail"
    ) {
      let dateCalendar = {
        dates: {
          startDate: responseLastSync?.lastSyncStartDate,
          endDate: responseLastSync?.lastSyncEndDate,
        },
      };
      localStorage.setItem(
        "lastSyncDates",
        JSON.stringify(dateCalendar?.dates)
      );
      setEndDate(
        responseLastSync?.lastSyncEndDate != "" &&
          responseLastSync?.lastSyncEndDate !== undefined &&
          responseLastSync?.lastSyncEndDate !== null
          ? dayjs(responseLastSync?.lastSyncEndDate).add(8, "day")
          : dayjs()
      );
      setStartDate(dayjs(responseLastSync?.lastSyncEndDate).add(1, "day"));
      setLastSyncDates({
        sDate: responseLastSync?.lastSyncStartDate,
        eDate: responseLastSync?.lastSyncEndDate,
      });
    } else {
      localStorage.removeItem("lastSyncDates");
    }
  }, [responseLastSync]);

  const handleClick = () => {
    context!.setData({
      dates: {
        startDate: startDate?.format("MM/DD/YYYY"),
        endDate: endDate?.format("MM/DD/YYYY"),
      },
    });
    localStorage.setItem(
      "dataBusinessCalendar",
      JSON.stringify({
        ...dataBusinessCalendar,
        dates: {
          startDate: startDate?.format("MM/DD/YYYY"),
          endDate: endDate?.format("MM/DD/YYYY"),
        },
      })
    );
    navigate(RoutesList.SYNC_ENTRIES_CONTAINER);
  };

  return (
    <>
      <MainContainer minHeight={"60vh"}>
        <Header />
        <Stack className={styles.selectDateContainer}>
          <FirstHeader>{UiData.selectDate.header}</FirstHeader>
          <Stack className={styles.dateContainer}>
            <Stack className={styles.startEndDateContainer}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateStack>
                  <DateContent>{UiData.selectDate.startDate}</DateContent>
                  <DatePicker
                    slots={{
                      openPickerButton: DateIcon,
                    }}
                    slotProps={{ textField: { size: "small" } }}
                    value={startDate}
                    maxDate={endDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                </DateStack>
                <DateStack>
                  <DateContent>{UiData.selectDate.endDate}</DateContent>
                  <DatePicker
                    slots={{
                      openPickerButton: DateIcon,
                    }}
                    slotProps={{ textField: { size: "small" } }}
                    value={endDate}
                    minDate={startDate}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </DateStack>
              </LocalizationProvider>
            </Stack>
            <Stack>
              <MainText className={styles.dateFooter}>
                {lastSyncDates?.sDate
                  ? `Dates last synced : ${lastSyncDates?.sDate} - ${lastSyncDates?.eDate}`
                  : null}
              </MainText>
            </Stack>
          </Stack>
          <Button onClick={handleClick}>{UiData.selectDate.buttonText}</Button>
        </Stack>
      </MainContainer>
    </>
  );
}

export default SelectDates;
