export const colors = {
  firstContent: "#576981",
  bottomHeader: "#062942",
  firstHeader: "#062942",
  mainText: "#576981",
  mainHeader: "#525252",
  paragraph: "#525252",
  cardHeader: "#28A48D",
  cardButton: "#1976D2",
  cardBorder: "#EDEDED",
  border: "#EDEDED",
  black: "#000",
  checkBoxPermission: "#B8B8B8",
  toaster: "#001B40",
  text: {
    primary: "#525252",
    secondary: "#C4C4C4",
  },
  btn: {
    disabledBackGround: "#C3E6B3",
    hoverBackground: "#348E09",
    background: "#36A900",
    text: "white",
    withoutBorder: "#576981",
    hover: {
      background: "#F5EB70",
      text: "#525252",
    },
  },
  muiIconcolor: "#062942",
  background: "#0075DD",
  info: "#F5EB70",
  error: "#FF0000",
  secondary: "#28A48D",
  modalTitle: "#F2F3F5",
  dateFilterButtonBackground: "#FFFFFF",
  dateFilterButtonBorder: "#7F8C9F",
  clickAbleLink: "#0063C1",
};
