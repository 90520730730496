import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { MainText, ToasterHeader } from "../components/Typography";
import { useToast } from "./ToastProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { colors } from "../constants/styles";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Link } from "@mui/material";

export type ToastProps = {
  header: string;
  content: string;
  id: number;
  success?: boolean;
  link?: string;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

export default function Toaster({ toasts }: { toasts: ToastProps[] }) {
  return (
    <>
      {toasts?.map((toast, index) => {
        return <Toast key={index} {...toast} />;
      })}
    </>
  );
}

const Toast = ({ header, content, id, success, link = "" }: ToastProps) => {
  // @ts-ignore
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={() => removeToast(id)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack
          sx={{
            width: "60%",
            backgroundColor: "white",
            color: "black",
            border: success
              ? `2px solid ${colors.btn.background}`
              : `2px solid ${colors.error}`,

            borderRadius: " 0px 0px 5px 5px",
            boxShadow: "4px 4px 0px rgba(6, 41, 66, 0.0710202)",
            padding: "12px",
          }}
        >
          <CloseIcon
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              cursor: "pointer",
            }}
            onClick={() => removeToast(id)}
          />

          <Stack direction="row" gap={1}>
            {success ? (
              <CheckOutlinedIcon sx={{ color: colors.btn.background }} />
            ) : null}
            <Stack gap={1} alignItems="flex-start">
              <ToasterHeader>{header}</ToasterHeader>

              <MainText textAlign="left">{content}</MainText>
              {link !== "" && (
                <MainText textAlign="left">
                  <Link href={link} underline="none" target="_blank">
                    {" "}
                    View Time Entries
                  </Link>
                </MainText>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Snackbar>
    </Stack>
  );
};
