export const UiData = {
  AccessPermission: {
    header: "Access Your Google Calendar",
    subHeader:
      "Please select all permission boxes show in the example below to allow access to your calendar and contacts on the following page. ",
    buttonText: "Sign in with Google",
    footerText1: "Need help? Check out our  ",
    faqLink: "https://googlecalendarfb.zapier.app/home/",
    footerText2: " for commonly asked questions.",
    firstPermission: "Associate you with your personal info on Google",
    secondPermission:
      "See your personal info, including any personal info you've made publicly available",
    thirdPermission: "See your primary Google Account email address",
    fourthPermission: "See the list of Google calendars you’re subscribed to",
    fifthPermission: "View events on all your calendars",
  },

  SyncCalendar: {
    header:
      "Welcome to FreshBooks Google Calendar App. Use this app to sync time tracking entries between FreshBooks and Google Calendar for easier invoicing.",
    firstImageHeader: "Import from Google Calendar",
    firstImageText:
      "Easily sync Appointment Data, like duration,date, and Client, from Google Calendar to FreshBooks.",
    secondImageHeader: "Review and Syno to Time Entries",
    secondImageText:
      "Review Calendar Events and select which Appointments to Sync to FreshBooks as Time Entries.",
    thirdImageHeader: "Invoice for Appointments",
    thirdImageText:
      "Once Appointment information has been synced, easily bill for outstanding time using the FreshBooks Invoices import tool.",
    primaryButtonText: "Sync Calendar Events",
    secondaryButtonText: "FreshBooks Invoice",
    secondaryButonUrl: "https://my.freshbooks.com/#/invoice/new",
    settingButton: {
      text: "Settings",
      firstOption: "Disconnect Calendar",
      secondOption: "Edit Business or Calendar",
    },
    toaster: {
      header: "Calendar Connected",
      footer: "You successfully set up Google Calendar in FreshBooks.",
    },
    toasterEdit: {
      header: "Settings Updated",
      footer: "Changes to your business and/or Calendar settings were saved.",
    },
    toasterSyncSuccess: {
      header: "Sync Complete ",
      footer: "Successfully imported time entries to Carrie’s Cloud Co.",
      link: "https://my.freshbooks.com/#/time-tracking",
    },
    gridItems: [
      {
        header: "Import Appointments",
        footer: "Sync appointments from Google Calendar.",
      },
      {
        header: "Create Time Entries",
        footer: "Add calendar events to FreshBooks Time Tracking.",
      },
      {
        header: "Easier Invoicing",
        footer: "Invoice for billable time entries in FreshBooks.",
      },
    ],
  },

  selectBusinessAndCalendar: {
    header: "Select Business and Calendar",
    headerEdit: "Edit Business and Calendar",
    firstQuestion: "Which business do you want to sync to?",
    secondQuestion: " Which calendar do you want to sync to?",
    footerText:
      "Events from the calendar selected will sync to the FreshBooks Business selected.",
    buttonText: "Next",
    buttonEdit: "Save",
  },

  selectDate: {
    header: "Select Date Range To Sync Calendar",
    startDate: "Start Date",
    endDate: "End Date",
    buttonText: "Next",
    footer: "Dates last synced:",
  },
  syncEntries: {
    apiFail: {
      header: "Something Went Wrong",
      footer:
        "An error occurred and some or all of your time entries were not synced. Please check Time Tracking in FreshBooks and try again. ",
      cancel: "Back",
      successBtn: "Try Again",
    },
    upgradePlan: {
      header: "You’ve Outgrown Your Plan",
      cancel: "Back to Edit",
      successBtn: "Manage Plan",
    },
  },
};

export const RoutesList = {
  DEFAULT: "/",
  HOME: "/home",
  CALENDAR_PERMISSION: "/calendar-permission",
  SYNC_CALENDAR_EVENTS: "/sync-calendar-events",
  SELECT_BUSINESS_AND_CALENDAR: "/select-business-calendar",
  SELECT_START_END_DATE: "/select-start-end-date",
  EDIT_BUSINESS_AND_CALENDAR: "/edit-business-calendar",
  DENY_ACCESS: "/deny-access",
  SYNC_ENTRIES_CONTAINER: "/sync-entries-container",
  READY_TO_SYNC: "/ready-to-sync",
  PRIVACY_POLICY: "/privacy-policy",
};
