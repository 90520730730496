import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { colors } from "../constants/styles";

export const FirstHeader = styled(Typography)`
  font-family: "Founders Grotesk";
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  color: ${colors.firstHeader};
  line-height: 32px;
`;

export const SyncEntriesFirstHeader = styled(FirstHeader)`
  color: ${colors.toaster};
`;

export const Paragraph = styled(Typography)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.firstHeader};
`;
export const SelectBoxText = styled(Typography)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.firstHeader};
`;
export const Footer = styled(Typography)`
  font-family: "Founders Grotesk";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.firstHeader};
`;
export const MainText = styled(Typography)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.mainText};
`;

export const ToasterHeader = styled(MainText)`
  font-weight: 600;
  color: ${colors.toaster};
`;

export const DateContent = styled(Typography)`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.toaster};
`;

export const PaginationFooter = styled(DateContent)`
  font-weight: 600;
`;

export const MainHeader = styled(Typography)`
  font-size: 27px;
  font-weight: 700;
  color: ${colors.mainHeader};
`;

export const SectionHeader = styled(MainHeader)`
  border-left: 8px solid ${colors.info};
  padding-left: 16px;
  text-transform: uppercase;
`;

export const Body = styled(Typography)`
  font-size: 21px;
  font-weight: 400;
  color: ${colors.paragraph};
`;

export const TeamClientText = styled(MainText)`
  color: ${colors.toaster};
`;

export const DateServiceText = styled(MainText)`
  font-size: 14px;
  line-height: 20px;
`;

export const GoogleHeader = styled(FirstHeader)`
  font-size: 36px;
  color: ${colors.toaster};
  line-height: 48px;
`;

export const ModalTitle = styled(FirstHeader)`
  color: ${colors.toaster};
`;
