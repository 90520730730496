import React from "react";
import { dateCalIcon, freshBookLogo } from "../assests/images";
import { Stack } from "@mui/material";

function Header() {
  return (
    <Stack direction="row" alignItems={"center"} gap={4} mb={2}>
      <img src={freshBookLogo} width={200}></img>
      <div style={{ fontWeight: "bold" }}>X</div>
      <img src={dateCalIcon} width={150}></img>
    </Stack>
  );
}

export default Header;
